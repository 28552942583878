import React, { memo } from 'react';
import Layout from "../components/layout/layout";

const days = [
  {
    "name": "Maandag",
    "frenchName": "Lundi",
    "englishName": "Monday",
    "soup": "Bloemkoolsoep",
    "frenchSoup": "Soupe de chou-fleur",
    "englishSoup": "Cauliflower soup",
    "mainCourse": "Pasta met butternut en feta",
    "frenchMainCourse": "Pâtes au butternut et feta",
    "englishMainCourse": "Pasta with butternut and feta",
    "dessert": null
  },
  {
    "name": "Dinsdag",
    "frenchName": "Mardi",
    "englishName": "Tuesday",
    "soup": "Preisoep",
    "frenchSoup": "Soupe aux poireaux",
    "englishSoup": "Leek soup",
    "mainCourse": "Herfstgroentengratin met feta",
    "frenchMainCourse": "Gratin de légumes d'automne à la feta",
    "englishMainCourse": "Autumn vegetable gratin with feta",
    "dessert": null
  },
  {
    "name": "Woensdag",
    "frenchName": "Mercredi",
    "englishName": "Wednesday",
    "soup": "Groenteroomsoep",
    "frenchSoup": "Crème de légumes",
    "englishSoup": "Vegetable cream soup",
    "mainCourse": "Vol-au-vent met frieten",
    "frenchMainCourse": "Vol-au-vent avec frites",
    "englishMainCourse": "Vol-au-vent with fries",
    "dessert": null
  },
  {
    "name": "Donderdag",
    "frenchName": "Jeudi",
    "englishName": "Thursday",
    "soup": "Knoflooksoep",
    "frenchSoup": "Soupe à l’ail",
    "englishSoup": "Garlic soup",
    "mainCourse": "Gegratineerde witloof",
    "frenchMainCourse": "Chicons gratinés",
    "englishMainCourse": "Gratinated endive",
    "dessert": null
  },
  {
    "name": "Vrijdag",
    "frenchName": "Vendredi",
    "englishName": "Friday",
    "soup": "Kippensoep",
    "frenchSoup": "Soupe de poulet",
    "englishSoup": "Chicken soup",
    "mainCourse": "Vispastei met groenten",
    "frenchMainCourse": "Tourte au poisson et légumes",
    "englishMainCourse": "Fish pie with vegetables",
    "dessert": null
  },
  {
    "name": "Zaterdag",
    "frenchName": "Samedi",
    "englishName": "Saturday",
    "soup": "Groene seldersoep",
    "frenchSoup": "Soupe de céleri vert",
    "englishSoup": "Green celery soup",
    "mainCourse": "Kipdij met mosterd en champignons, aardappelen uit de oven",
    "frenchMainCourse": "Cuisses de poulet à la moutarde et aux champignons, pommes de terre au four",
    "englishMainCourse": "Chicken thighs with mustard and mushrooms, baked potatoes",
    "dessert": null
  },
  {
    "name": "Zondag",
    "frenchName": "Dimanche",
    "englishName": "Sunday",
    "soup": "Erwtensoep",
    "frenchSoup": "Soupe de petits pois",
    "englishSoup": "Pea soup",
    "mainCourse": "Boeuf bourguignon met aardappelen",
    "frenchMainCourse": "Boeuf bourguignon avec des pommes de terre",
    "englishMainCourse": "Beef bourguignon with potatoes",
    "dessert": null
  }
];
const alternativeMenu = {
  "dutch": "Kalfsblanquette met frietjes",
  "french": "Blanquette de veau avec frites",
  "english": "Veal blanquette with fries"
};
const Lunch = () => {
  return (
      <>
          <Layout/>
          <main className="main">
              <section className='lunch'/>
              <div className="best_price">
                  <img
                      loading='lazy'
                      src="/images/best_price_2.webp"
                      alt="Lunch"
                  />
              </div>
              <div className="top_title">
                  <h1>Lunch</h1>
              </div>

              <div className='lunch_wrap'>
                  <div className='lunch_box'>
                      <h3>11/11 - 17/11</h3>
                      <h3>11:30 – 15:00</h3>
                              {/* Альтернативное меню */}
                      {/* <div className="alternative_menu">
                          <h3 style={{color:'#ae975f'}}>Alternatief gerecht voor deze week:</h3>
                          <p style={{color:'#ae975f'}}>{alternativeMenu.dutch} - {alternativeMenu.french} - {alternativeMenu.english}</p>
                      </div>       */}
                      {days.map((day, index) => (
                          <div key={index}>
                              <h4>{day.name} – {day.frenchName} – {day.englishName}</h4>
                              <p>{day.soup} - {day.frenchSoup} - {day.englishSoup}</p>
                              <p>{day.mainCourse}</p>
                              <p>{day.frenchMainCourse}</p>
                              <p>{day.englishMainCourse}</p>
                              {day.dessert && (
                                  <p>{day.dessert} - {day.frenchDessert} - {day.englishDessert}</p>
                              )}
                          </div>
                      ))}
                  </div>
              </div>
          </main>
      </>
  );
};


export default memo(Lunch);
